import Image from "next/image";
import Link from "@/components/ui/Link";
import BreadCrumbs from "../BreadCrumbs";
import ImagePattern from "../ImagePattern";
import { getReadableImageUrl } from "@/lib/directus";
import { getGalleryPatientUrl } from "@/lib/gallery";

interface ProcedureSectionProps {
  data?: any;
}

export default function ProcedureSection({ data }: ProcedureSectionProps) {
  return (
		<section id='ProcedureSection' className='py-12 bg-[#b1a3a426]'>
			<div className='container space-y-6'>
				<div className='flex justify-center w-full'>
					<Link
						href='/gallery/'
						className='inline-block px-8 py-5 rounded-full text-xs font-aileron font-bold uppercase tracking-wider transition-colors bg-black border border-white hover:bg-[#645e5e] text-white'
					>
						Back to Main Gallery
					</Link>
				</div>

				<div className='grid md:grid-cols-3 grid-cols-1 gap-6 p-4'>
					{data.patients.map(
						(patient, index: number) =>
							patient.photos &&
							patient.photos.length > 0 && (
								<Link
									key={index}
									href={getGalleryPatientUrl(data.procedureSlug, patient.id)}
									className='block transition-opacity hover:opacity-90 m-2 bg-[#282828]'
								>
									<div className='relative flex items-center justify-center md:h-full'>
										<Image
											src={getReadableImageUrl(
												patient.photos[0].directus_files_id.id,
												patient.photos[0].directus_files_id.filename_disk
											)}
											alt='Before & After Procedure'
											width={200}
											height={150}
											className='w-full object-cover'
											quality={95}
											loading={'lazy'}
										/>
										<ImagePattern className='text-sm' />
									</div>
									<div className='bg-black py-3 text-center md:hidden'>
										<span className='text-white font-medium'>
											VIEW PATIENT DETAIL
										</span>
									</div>
								</Link>
							)
					)}
				</div>
			</div>
		</section>
	)
}
