import Link from "@/components/ui/Link";
import Button from "@/components/ui/button";
import { getGalleryCategoryUrl } from "@/lib/gallery";

interface GalleryCategoryData {
  categories: {
    title: string;
    slug: string;
  }[];
}

interface GalleryIndexProps {
  data: GalleryCategoryData;
}
export default function GalleryIndex({ data }: GalleryIndexProps) {
  return (
    <section id="gallery-index" className="py-20 md:py-24">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="space-y-6 flex flex-col items-center">
            {data.categories
              .slice(0, Math.ceil(data.categories.length / 2))
              .map((category) => (
                <div key={category.slug} className="block">
                  <Button asChild variant="outline">
                    <Link href={getGalleryCategoryUrl(category.slug)}>
                      {category.title}
                    </Link>
                  </Button>
                </div>
              ))}
          </div>
          <div className="space-y-6 flex flex-col items-center">
            {data.categories
              .slice(Math.ceil(data.categories.length / 2))
              .map((category) => (
                <div key={category.slug} className="block">
                  <Button asChild variant="outline">
                    <Link href={getGalleryCategoryUrl(category.slug)}>
                      {category.title}
                    </Link>
                  </Button>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}
