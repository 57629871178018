import { useState, useEffect } from "react";
import Image from "next/image";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { PlusIcon, X } from "lucide-react";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";

interface GalleryGridData {
  heading: {
    text?: string;
    title?: string;
  };
  images: {
    images_id: {
      alt?: string;
      image_file: {
        image: {
          id: string;
          filename_download: string;
        };
      };
    };
  }[];
}

export const fields = [
  "heading.text",
  "heading.title",
  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",
] as const;

interface GalleryGridProps {
  data: GalleryGridData;
}

export default function GalleryGrid({ data }: GalleryGridProps) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") setSelectedIndex(null);
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, []);

  const galleryImages = data?.images;

  return (
    <>
      <section
        id="gallery-grid"
        className="w-full bg-[rgba(177,163,164,0.15)] py-14"
      >
        <div className="max-w-[1080px] mx-auto px-4">
          <h2
            className="font-display text-4xl md:text-[44px] text-center mb-6 leading-tight"
            dangerouslySetInnerHTML={sanitizeHTML(
              data?.heading?.title || data?.heading?.text || "Gallery"
            )}
          />
          <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-14">
            {galleryImages.slice(0, 4).map((item, index) => (
              <div
                key={index}
                onClick={() => setSelectedIndex(index)}
                className="relative group overflow-hidden rounded-[13px] cursor-pointer lg:max-h-[160px]"
              >
                <Image
                  src={getReadableImageUrl(
                    item.images_id.image_file.image.id,
                    item.images_id.image_file.image.filename_download
                  )}
                  alt={item.images_id.alt || ""}
                  width={400}
                  height={284}
                  className="w-full h-auto object-cover"
                  quality={95}
                  loading={"lazy"}
                />
                <div className="absolute inset-0 bg-white/90 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <PlusIcon className="size-10" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {selectedIndex !== null && (
        <div
          style={{ zIndex: 99999 }}
          className="fixed inset-0 overflow-hidden"
        >
          <div
            className="absolute inset-0 bg-black/50 backdrop-blur-sm"
            onClick={() => setSelectedIndex(null)}
          />
          <div className="relative w-full h-full flex items-center justify-center p-4 md:p-8">
            <button
              onClick={() => setSelectedIndex(null)}
              className="absolute top-8 right-8 text-white hover:text-gray-300"
            >
              <X className="size-8" />
            </button>
            <div className="w-full max-w-6xl md:px-10">
              <Carousel
                opts={{
                  align: "center",
                  loop: true,
                  startIndex: selectedIndex,
                }}
                className="w-full"
              >
                <CarouselContent>
                  {galleryImages.map((item, index) => (
                    <CarouselItem key={index} className="relative pb-10">
                      <div className="relative h-[65vh]">
                        <Image
                          src={getReadableImageUrl(
                            item.images_id.image_file.image.id,
                            item.images_id.image_file.image.filename_download
                          )}
                          alt={item.images_id.alt || ""}
                          fill
                          className="object-contain"
                          quality={95}
                          sizes="(max-width: 768px) 100vw, 90vw"
                          loading={"lazy"}
                        />
                      </div>
                      <p className="text-white text-center text-sm mt-4">
                        {item.images_id.alt}
                      </p>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious className="hidden md:block text-white border-white hover:bg-white hover:text-black -left-16" />
                <CarouselNext className="hidden md:block text-white border-white hover:bg-white hover:text-black -right-16" />
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
