import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "@/components/ui/Link";
import Button from "../ui/button";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { PlusIcon, X } from "lucide-react";

interface GalleryMasonryData {
  heading: {
    title?: string;
    text?: string;
  };
  images: {
    images_id: {
      alt?: string;
      image_file?: {
        image: {
          id: string;
          filename_download: string;
        };
      };
    };
  }[];
  button?: {
    label?: string;
    href?: string;
  };
}

export const fields = [
  "heading.title",
  "heading.text",
  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",
  "button.label",
  "button.href",
] as const;

interface GalleryMasonryProps {
  data: GalleryMasonryData;
}

export default function GalleryMasonry({ data }: GalleryMasonryProps) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") setSelectedIndex(null);
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, []);

  return (
    <>
      <section id="gallery-masonry" className="w-full bg-white py-16">
        <div className="max-w-[1080px] mx-auto px-4">
          <h2
            className="text-4xl md:text-5xl font-freightBig text-center mb-12"
            dangerouslySetInnerHTML={sanitizeHTML(
              data?.heading?.title || data?.heading?.text || ""
            )}
          />
          <div className="columns-2 md:columns-4 gap-4">
            {data?.images?.map((item, index) => (
              <div
                key={index}
                className="relative mb-4 break-inside-avoid cursor-pointer group"
                onClick={() => setSelectedIndex(index)}
              >
                <div className="relative aspect-auto">
                  <Image
                    src={getReadableImageUrl(
                      item?.images_id?.image_file?.image?.id || "",
                      item?.images_id?.image_file?.image?.filename_download ||
                        ""
                    )}
                    alt={item?.images_id?.alt || ""}
                    width={500}
                    height={500}
                    className="w-full h-auto object-cover"
                    sizes="(max-width: 768px) 50vw, 25vw"
                    quality={95}
                    loading={"lazy"}
                  />
                  <div className="absolute inset-0 bg-white/90 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <PlusIcon className="size-10" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {data?.button?.label && data?.button?.href && (
            <div className="mt-12 text-center">
              <Button asChild>
                <Link href={data.button.href}>{data.button.label}</Link>
              </Button>
            </div>
          )}
        </div>
      </section>

      {selectedIndex !== null && (
        <div
          style={{ zIndex: 99999 }}
          className="fixed inset-0 overflow-hidden"
        >
          <div
            className="absolute inset-0 bg-black/50 backdrop-blur-sm"
            onClick={() => setSelectedIndex(null)}
          />
          <div className="relative w-full h-full flex items-center justify-center p-4 md:p-8">
            <button
              onClick={() => setSelectedIndex(null)}
              className="absolute top-8 right-8 text-white hover:text-gray-300"
            >
              <X className="size-8" />
            </button>
            <div className="w-full max-w-6xl md:px-10">
              <Carousel
                opts={{
                  align: "center",
                  loop: true,
                  startIndex: selectedIndex,
                }}
                className="w-full"
              >
                <CarouselContent>
                  {data?.images?.map((item, index) => (
                    <CarouselItem key={index} className="relative pb-10">
                      <div className="relative h-[65vh]">
                        <Image
                          src={getReadableImageUrl(
                            item?.images_id?.image_file?.image?.id || "",
                            item?.images_id?.image_file?.image
                              ?.filename_disk || ""
                          )}
                          alt={item?.images_id?.alt || ""}
                          fill
                          className="object-contain"
                          sizes="(max-width: 768px) 100vw, 90vw"
                          quality={95}
                          loading={"lazy"}
                        />
                      </div>
                      <p className="text-white text-center text-sm mt-4">
                        {item?.images_id?.alt}
                      </p>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious className="hidden md:block text-white border-white hover:bg-white hover:text-black -left-16" />
                <CarouselNext className="hidden md:block text-white border-white hover:bg-white hover:text-black -right-16" />
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
