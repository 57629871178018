import Link from "@/components/ui/Link";
import { getGalleryCategoryUrl, getGalleryPatientUrl } from "@/lib/gallery";

import dynamic from "next/dynamic";

const BeforeAfterGallery = dynamic(() => import("../PatientCarousel"), { ssr: false });
const OtherPatientsCarousel = dynamic(() => import("../OtherPatientsCarousel"), { ssr: false });
const BreadCrumbs = dynamic(() => import("../BreadCrumbs"), { ssr: false });
import Button from "@/components/ui/button";

interface PatientSectionProps {
  data: {
    procedure: any;
    patient: any;
    patients: any;
  };
}

export default function PatientSection({ data }: PatientSectionProps) {
  const { procedure, patient, patients } = data;
  
  // Find current patient index
  const currentIndex = patients.findIndex(p => p.id === patient.id);
  const prevPatient = currentIndex > 0 ? patients[currentIndex - 1] : null;
  const nextPatient = currentIndex < patients.length - 1 ? patients[currentIndex + 1] : null;

  return (
    <section id="PatientSection" className="py-6 md:py-12 bg-[#b1a3a426]">
      <div className="container max-w-[1536px] space-y-6">
        <BreadCrumbs className="max-[768px]:text-lg" segments={[
          { 
            title: procedure.title, 
            url: getGalleryCategoryUrl(procedure.slug) 
          },
          { 
            title: patient.title, 
          }
        ]} />

        <div className="flex justify-between items-center uppercase">
          {prevPatient && (
            <Button className="p-4 md:px-8 md:text-lg" asChild>
              <Link href={getGalleryPatientUrl(procedure.slug, prevPatient.id)}>
                Previous Patient
              </Link>
            </Button>
          )}

          {nextPatient && (
            <Button className="ml-auto p-4 md:px-8 md:text-lg" asChild>
              <Link href={getGalleryPatientUrl(procedure.slug, nextPatient.id)}>
                Next Patient
              </Link>
            </Button>
          )}
        </div>
        <BeforeAfterGallery procedureTitle={procedure.title} patient={patient} />
        <OtherPatientsCarousel patients={patients} procedureSlug={procedure.slug} />
      </div>
    </section>
  );
}
