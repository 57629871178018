import { HTMLAttributes } from "react";

interface ImagePatternProps extends HTMLAttributes<HTMLDivElement> {}

const ImagePattern = ({ ...props }: ImagePatternProps) => {
  return (
    <div {...props} id="image-pattern-before-after">
      <span className="font-aileron font-bold uppercase absolute bottom-0 left-0 text-white pb-[3%] pl-[5%] text-[100%]">
        Before
      </span>
      <span className="font-aileron font-bold uppercase absolute bottom-0 right-0 text-white pb-[3%] pr-[5%] text-[100%]">
        After
      </span>
      <span className="absolute top-0 left-1/2 bg-white h-full w-1 transform -translate-x-1/2" />
    </div>
  );
};

export default ImagePattern;
